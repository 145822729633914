<template>
  <v-app>
    <v-app-bar
      app
      color="info"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Performance Signs Logo"
          class="mr-2"
          
          src="./assets/ps-logo.png"
          transition="scale-transition"
          width="250"
        />

      <div class="d-none d-sm-block text-h5">VDOT Pre-Construction Conference Submittal</div>  
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <router-view/>
       
      <v-footer
      color="secondary lighten-1"
      padless
      height="75px"
    >
      
      <!--<v-row justify="center" no-gutters class="d-none d-md-flex">        
        <v-btn height="75px" href="https://rivannatechnologies.com" class="my-2" color="#5CDB95" plain>Application developed by Rivanna Technologies LLC.</v-btn>
        
      </v-row>
      <v-row justify="center" no-gutters class="d-md-none">        
        <v-btn height="75px" href="https://rivannatechnologies.com" class="my-2" color="#5CDB95" plain>Developed by Rivanna Technologies LLC.</v-btn>
        
      </v-row>-->
    </v-footer>
    </v-main>
    
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
  created () {
    this.$plausible.enableAutoPageviews();
  },
  watch: {
    '$route' (to) {
      document.title = to.meta.title || 'Performance Signs & Wraps'
    }
  }
};
</script>
