import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { VuePlausible } from 'vue-plausible'
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
Vue.use(VuePlausible, {
  // see configuration section
  domain: "vdot.performancesigns.net",
  enableAutoPageviews: true
})
const toastoptions = {  
    timeout: 3000,
    position: "top-center"
};


Vue.use(Toast, toastoptions);
Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
